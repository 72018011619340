export default {
  tasks: {
    userdata({ api, cache }, id) {
      const key = `search-user__${id}`;
      return [
        cache.load(key, null),
        api
          .res('users', 'search')
          .get({ id })
          .then(({ data }) => {
            cache.save(key, data);
            return data;
          })
          .catch(({ response }) => {
            if (response && response.status == 404) {
              cache.save(key, null);
            }
            return null;
          }),
      ];
    },
  },
};
