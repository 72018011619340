<script>
export default {
  props: ['menu'],
  data: () => ({

  })
}
</script>

<template>
  <div class="page-cont">
    <div class="page-submenu" v-if="menu">
      <div class="page-submenu__wrapper">
        <slot name="submenu"></slot>
      </div>
      <div class="page-submenu__spacer"></div>
    </div>
    <div id="inner_block">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="less">

.page-cont {
  .default-paper();
  border: 1px solid @smoke;
  border-width: 1px 0px;
  @media @desktop {
    border-width: 2px;
  }
  @media (prefers-color-scheme: dark) {
    border-color: @gray-dark;
  }
}

.page-submenu {
  display: flex;

  .colon-link-menu();

  // overflow: hidden;

  &__wrapper {
    // position: relative;
    flex-grow: 0;
    .sliding();
    font-size: 0px;
    background: @smoke;
    @media (prefers-color-scheme: dark) {
      background: @gray-dark;
    }
    border-bottom-right-radius: 2px;
    padding: 0px 10px;
  }

  &__item {

    &::after {
      @media (prefers-color-scheme: dark) {
        color: @gray;
      }
    }
    //
    // &.active {
    //   border: 3px solid @primary;
    //   border-width: 0px 0;
    // }
  }

  &__spacer {
    flex: 1 1 auto;
  }
}

#inner_block {
  position: relative;
  padding: 15px 10px;
  margin-bottom: 10px;
}

#caption {
    font-size: 16px;
    margin-bottom: 10px;
}
.caption {
    font-size: 20px;
    margin-bottom: 10px;
    span {
        color: @gray-dark;
        font-size: 15px;
        margin-left: 5px;
    }
}

.reference {
    color: @gray-dark;
    font-size: 14px;
    margin-bottom: 15px;
}
</style>
