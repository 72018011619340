<script>
import AnketaActivity from '~activities/AnketaActivity.vue';
import AccountComponent from '@freedomsex/account-component';

export default {
  name: 'UserAnketa',
  props: ['id', 'resource'],
  data: () => ({
    show: false,
    human: null,
    loading: true,
    error: false,
  }),
  components: {
    AnketaActivity,
    AccountComponent,
  },
  mounted() {
    if (this.resource) {
      this.human = this.resource;
    } else {
      this.load();
    }
  },
  methods: {
    async load() {
      try {
        const { data } = await this.$api.res('users', 'search').get({ id: this.id });
        this.human = data;
      } catch (e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    item() {
      return 0;
    },
  },
};
</script>

<template>
  <div>
    <div class="user-anketa-id">
      Номер анкеты:
      <span v-if="!error">
        <b class="user-anketa-id__number">{{ id }}</b>
        <span class="link_simple" @click="show = true" v-show="!error">Смотреть</span>
      </span>
      <span v-else>
        <s class="user-anketa-id__number">{{ id }}</s>
        <b class="red_link">АНКЕТА УДАЛЕНА</b>
      </span>
    </div>

    <AnketaActivity :resource="human" @close="show = false" v-if="show" />
  </div>
</template>

<style lang="less">
.user-anketa-id {
  margin-bottom: @indent-md;

  &__number {
    margin-right: @indent-xs;
  }
}

.special-form {
  background: @alert-sand;
  padding: @indent-md @indent-md;
  border: 1px solid #f6e4a2;
  border-width: 1px 0 1px;
  border-radius: 4px;
}
</style>
