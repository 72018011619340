<script>
import Authenticator from '@freedomsex/authenticator/Authenticator.vue';
import TTLColorBadge from '@freedomsex/authenticator/TTLColorBadge.vue';
import RefreshOnFocus from '@freedomsex/authenticator/RefreshOnFocus.vue';

import cookies from '~assets/utils/cookies';


export default {
  data: () => ({
    left: 0,
    trigger: 60,
  }),
  mixins: [RefreshOnFocus],
  components: {
    Authenticator,
    TTLColorBadge,
  },
  computed: {
    ttl() { return this.$store.state.token.access_ttl; },
    lifetime() { return this.$store.state.token.lifetime; },
  },
  mounted() {
    this.fallback();
  },

  methods: {
    fallback() {
      const sid = cookies.get('sexid');
      let {refresh} = this.$store.state.token;
      if (!refresh && sid) {
        this.$store.commit('token/fallback', sid);
        this.refresh();
      }
    },

    tick(left) {
      this.left = left;
      this.$service.run('auth/tick');
    },
    refresh() {
      this.$service.run('auth/refresh').then(() => {
        this.$refs.authenticator.reset();
      });
    },
  },
};
</script>

<template>
  <div class="authenticator" v-if="1">
    <Authenticator ref="authenticator" @tick="tick" @refresh="refresh"
    :token="$store.state.token" :trigger="trigger" :auth="1" :period="3"/>
    <TTLColorBadge v-if="ttl && left" @refresh="refresh" :ttl="ttl" :lifetime="lifetime" :left="left"/>
  </div>

</template>

<style lang="less">
.authenticator {
  position: relative;
  bottom: 2px;
  text-align: center;
  position: relative;
  margin: 0 10px 10px;
  opacity: 0.8;
}
</style>
