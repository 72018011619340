import expunix from 'expires-unixtime';
import cookies from '../../../assets/utils/cookies';

export default {
  tasks: {
    restore({ store, cache }) {
      if (!store.state.token.refresh) {
        const data = cache.load('token', null);
        if (data) {
          store.commit('token/save', data);
        }
      }
    },


    refresh({
      api, store, cache, root,
    }) {
      const token = store.state.token.refresh || cache.load('sexid') || cookies.get('sexid') || null;
      if (!token) {
        return Promise.reject('Empty refresh token!');
      }
      return api.res('refresh', 'auth').post({ token }).then(({ data }) => {
        root.run('auth/token', data);
        return data;
      }).catch(({ response }) => {
        if (response) {
          console.log(response.status);
          // if ([401, 400].indexOf(response.status) >= 0) {
          //   // store.commit('token/logout');
          //   // store.commit('authorize', false);
          //   root.run('auth/logout');
          // }
        }
      });
    },

    flash({ api, store }) {
      const { access, refresh } = store.state.token;
      api.saveAuthKey(access, 'default');
      api.saveAuthKey(refresh, 'raw');
    },

    tick({ root, store }) {
      const { access, expires } = store.state.token;
      if (!access || !expires) {
        store.commit('authorize', false);
      } else {
        const expired = expunix.expired(expires);
        store.commit('authorize', !expired); // 3600
        if (!expired) {
          root.run('auth/flash');
        }
      }
    },

    token({ root, store, cache }, data) {
      if (data && data.token) {
        store.commit('token/save', data);
        store.commit('authorize', true);
        cache.save('token', data);
        cache.save('sexid', store.state.refresh);
        root.run('auth/flash');
      }
    },
  },
};
