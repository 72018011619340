export default {
  namespaced: true,
  state: {
    rank: 0,
  },
  mutations: {
    rank(state, value) {
      state.rank = value;
    },
  },
};
