<script>
import MarkerTextColored from '@freedomsex/marker-text-colored';

export default {
  props: ['item'],
  components: {
    MarkerTextColored,
  },
  data: () => ({
    // item: null,
  }),
  mounted() {

  },
  computed: {
    marker() {
      if (this.item.rank > 0 ) {
        return 4;
      }
      if (this.item.rank < 0 ) {
        return 1;
      }
      return 0;
    },
  },
  methods: {
    date(item, index) {
      let previous = null;
      if (index && index < this.list.length) {
        previous = this.list[index - 1].updated;
      }
      return ListDateFormatter.date(item.updated, previous);
    },
  }
}
</script>

<template>
  <div class="messages-colored__item" v-if="item">
    <slot></slot>
    <MarkerTextColored :marker="marker" @select="$router.push('/user-profile/'+item.userId)">
      <template slot="summary">

        <span v-if="item.userId">
          Жалоба на
          <span class="link_simple gray" @click="$router.push('/user-profile/'+item.userId)">
            #{{item.userId}}
          </span>
          <span>
            [{{item.rank}}/{{item.absolute}}/{{item.vote}}/{{item.level}}]
          </span>
        </span>

        <span v-if="item.moderator">
          - от
          <span class="link_simple gray" @click="$router.push('/admin-profile/'+item.moderator)">
            @{{item.moderator}}
          </span>
        </span>



        <span v-if="item.updated">
          <i class="material-icons">update</i>
          {{$moment(item.updated).fromNow()}}
        </span>
        <span v-if="item.added">
          ⚐ {{$moment(item.added).fromNow()}}
        </span>
    </template>
    {{item.message}}
    </MarkerTextColored>

  </div>
</template>

<style lang="less">
.text-item-colored {
  font-size: 14px;
}
.marker-text-colored__summary {
  .sliding();
}

.messages-colored {
  position: relative;
  min-height: 200px;

  &__item {
    white-space: normal;
    margin-bottom: 15px;
    .material-icons {
      font-size: 14px;
      line-height: 1;
      vertical-align: text-top;
    }
  }

  a {
    display: inline-block;
  }
  &__pages {
    font-size: 16px;
    margin-top: @indent-lg;
  }
  &__placeholder {
    padding: @indent-lg 0 @indent-sm;
  }
  h4 {
    color: @gray-dark;
  }
}
</style>
