export default {
  namespaced: true,
  state: {
    fastScroll: false,
  },
  mutations: {
    setScroll(state) {
      state.fastScroll = state.fastScroll === false;
    },
  },
};
