<script>
import Qs from 'qs';
import VotingColored from '~widgets/messages/VotingColored';
import SplashLoader from '~widgets/SplashLoader';
import Pagination from '~widgets/Pagination';
import MaskLoader from '~widgets/MaskLoader';
import ListDateFormatter from './ListDateFormatter';

export default {
  props: ['category'],
  data: () => ({
    list: [],
    loading: true,
    initialized: false,
    error: false,
    filters: {
      page: 1,
      rank: {
        gte: null,
        lte: null,
      }
    }
  }),
  components: {
    VotingColored,
    SplashLoader,
    MaskLoader,
    Pagination,
  },
  mounted() {
    this.load();
  },
  methods: {
    setRank() {
      this.filters.rank = {
        gte: -100,
        lte: 100,
      }
      if (this.category == 'warning') {
        this.filters.rank = {
          gte: 1,
          lte: 100,
        }
      }
      if (this.category == 'success') {
        this.filters.rank = {
          gte: -100,
          lte: -1,
        }
      }
      if (this.category == 'unknown') {
        this.filters.rank = {
          gte: -5,
          lte: 5,
        }
      }
    },
    async load(top) {
      let params = null;
      this.loading = true;
      if (top) {
        this.$refs.pagination.top();
      }
      this.setRank();
      this.$api.setAxiosConfig({
        paramsSerializer(params) {
          return Qs.stringify(params, { arrayFormat: 'indices', encodeValuesOnly: true });
        },
      });

      const { data } = await this.$api.res('message_votings', 'a4sex').load(this.filters);
      this.list = data;
      this.loading = false;
      this.initialized = true;
    },
    reset() {
      this.filters.page = 1;
      this.load(true);
    },
    date(item, index) {
      let previous = null;
      if (index && index < this.list.length) {
        previous = this.list[index - 1].updated;
      }
      return ListDateFormatter.date(item.updated, previous);
    },
    next() {
      this.filters.page += 1;
      this.load(true);
    },
    reload() {
      this.load(true);
    },
    reset() {
      this.filters.page = 1;
      this.load(true);
    },
    abort() {
      this.loading = false;
    },
  },
}
</script>

<template>
  <div class="">
    <div class="reference">
      Список голосования. Эти жалобы ещё находятся на голосовании. Вы можете принять решение до окончания голосования.
      <a class="link_simple" href="http://docs.freedomsex.info/blog/#/Сообщество/Администрирование?id=Мониторинг-решений"
       target="_blank">Подробнее..</a>
    </div>

    <div class="messages-colored" v-if="initialized">
      <div v-if="list.length">
        <VotingColored :item="item" v-for="(item, index) in list" :key="item.id">
          <div class="date_sep" v-if="date(item, index)">
            <span>{{date(item, index)}}</span>
          </div>
        </VotingColored>
      </div>

      <div class="messages-colored__placeholder" v-if="!loading && !list.length">
        <h4>Конец списка</h4>
      </div>

      <div class="messages-colored__pages">
        <Pagination ref="pagination" @next="next" @reload="reload" @reset="reset" />
      </div>

      <MaskLoader @cancel="abort" v-if="loading"/>
    </div>
    <SplashLoader v-else/>

  </div>
</template>

<style lang="less">

</style>
