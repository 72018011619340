<script>
export default {
  props: [],
  computed: {
    fast() {
      return this.$store.state.settings.fastScroll;
    },
  },
  methods: {
    top() {
      global.scrollTo({top: 0, behavior: this.fast ? 'instant' : 'smooth'});
    },
  },
};
</script>

<template>
  <div class="">
    <button class="btn btn-default" @click="$emit('next')">Следующие</button>
    <button class="btn btn-default" @click="$emit('reload')">
      <span class="glyphicon glyphicon-repeat" aria-hidden="true"></span>
    </button>
    <button class="btn btn-default" @click="$emit('reset')">
      <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
    </button>
    <div class="pull-right">
      <button class="btn btn-default active"  @click="$store.commit('settings/setScroll')">
        <span v-if="fast">
          <span class="glyphicon glyphicon-fast-forward" aria-hidden="true"></span>
        </span>
        <span v-else>
          <span class="glyphicon glyphicon-step-forward" aria-hidden="true"></span>
        </span>
      </button>
      <button class="btn btn-default" @click="top()">
        <span class="glyphicon glyphicon-menu-up" aria-hidden="true"></span>
      </button>
    </div>
  </div>
</template>

<style lang="less">

</style>
