export default {
  tasks: {
    status({ api, cache }, id) {
      const key = `profile-status__${id}`;
      return [
        cache.load(key, null),
        api.res('captcha_infos', 'a4sex').get({ id }).then(({ data }) => {
          cache.save(key, data);
          return data;
        }),
      ];
    },
  },
};
