<script>
import MarkerTextColored from '@freedomsex/marker-text-colored';

export default {
  name: 'MessageColored',
  props: ['id', 'resource', 'hash', 'info'],
  components: {
    MarkerTextColored,
  },
  data: () => ({
    item: null,
  }),
  mounted() {
    if (this.id) {
      this.load();
    } else if (this.hash) {
      this.loadByHash();
    }
    if (this.resource) {
      this.item = this.resource;
    }
  },
  methods: {
    async load() {
      const { data } = await this.$api.res('message_markers', 'a4sex').get({id: this.id});
      this.item = data;
      this.loading = false;
      this.initialized = true;
    },
    async loadByHash() {
      const { data } = await this.$api.res('message_markers', 'a4sex').load({
        hash: this.hash,
      });
      this.item = data[0];
      this.loading = false;
      this.initialized = true;
    },
  }
}
</script>

<template>
  <div class="messages-colored__item" v-if="item">
    <slot></slot>
    <MarkerTextColored :marker="item.marker" @select="$router.push('/message-history/'+item.id)">
      <template slot="summary">
        <span v-if="item.remoder">Арбитраж</span>
        <a class="link_simple" :href="'/admin/arbitr/'+item.id" v-else>
          Арбитраж
        </a> -
        <span class="link_simple gray" @click="$router.push('/message-history/'+item.id)">
          История
        </span>
        <span v-if="item.visor">
          - принято
          <span class="link_simple gray" @click="$router.push('/admin-profile/'+item.visor)">
            @{{item.visor}}
          </span>
        </span>
        <span v-if="item.updated">
          <i class="material-icons">update</i>
          {{$moment(item.updated).fromNow()}}
        </span>
        <template v-if="info">
          <span v-if="info.added">
            - от
            <span class="link_simple gray" v-if="info.moderId"
             @click="$router.push('/user-profile/'+item.moderId)">
              #{{info.moderId}}
            </span>
            ⚐ {{$moment(item.added).fromNow()}}
          </span>
        </template>

    </template>
    {{item.text}}
    </MarkerTextColored>

  </div>
</template>

<style lang="less">
.text-item-colored {
  font-size: 14px;
  @media (prefers-color-scheme: dark) {
    background: @dark;
    color: @light;
  }
}
.marker-text-colored__summary {
  .sliding();
}

.messages-colored {
  position: relative;
  min-height: 200px;

  &__item {
    white-space: normal;
    margin-bottom: 15px;
    .material-icons {
      font-size: 14px;
      line-height: 1;
      vertical-align: text-top;
    }
  }

  a {
    display: inline-block;
  }
  &__pages {
    font-size: 16px;
    margin-top: @indent-lg;
  }
  &__placeholder {
    padding: @indent-lg 0 @indent-sm;
  }
  h4 {
    color: @gray-dark;
  }
  .date_sep {
    border-width: 1px 0 1px 0;
    border: 1px dashed @gray;
    margin-bottom: 15px;
    padding: 5px;
    text-align: center;
    span {
      font-size: 14px;
    }
  }
}
</style>
