<script>
import DefaultActivity from '@freedomsex/activity-component';

export default {
  props: ['id'],
  data: () => ({
    items: [],
    loading: true,
    error: false,
  }),
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      try {
        const { data } = await this.$api.res('histories', 'a4sex').load({ userId: this.id });
        this.items = data;
      } catch (e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    analyse(index, key, value) {
      if (index && value) {
        return value != this.items[index - 1][key];
      }
      return false;
    },
    sex(value) {
      if (value) {
        return value == 2 ? '♀ Девушка' : '♂ Парень';
      }
      return '☯ Другой';
    },
    date(value) {
      return this.$moment(value).fromNow();
    },
  },
  computed: {
  },
  components: {
    DefaultActivity,
  },
};
</script>

<template>
  <DefaultActivity caption="История данных" type="wrapped" @close="$emit('close')">

    <table class="data-table">
      <tr class="row" v-for="(item, index) in items">
        <td class="cell" :class="{changed: analyse(index, 'sex', item.sex)}">{{sex(item.sex)}}</td>
        <td class="cell" :class="{changed: analyse(index, 'city', item.city)}">{{item.city}}</td>
        <td class="cell" :class="{changed: analyse(index, 'name', item.name)}">{{item.name}}</td>
        <td class="cell">➚ {{date(item.added)}}</td>
      </tr>

    </table>

    <div class="" v-if="loading">
      Загружаю...
    </div>
    <div class="" v-else-if="error || !items.length">
      Нет данных :(
    </div>


  </DefaultActivity>
</template>

<style lang="less" scoped>
@import '~styles/variables.less';

.data-table {

  .row {
    border-bottom: 1px solid @gray-light;
  }
  .cell {
    padding: @indent-sm @indent-sm;
    &.changed {
      background: @red-light;
    }
  }
}
</style>
