<script>
import ListHeader from './ListHeader';
import MessageColored from '~widgets/messages/MessageColored';
import SplashLoader from '~widgets/SplashLoader';
import Pagination from '~widgets/Pagination';
import MaskLoader from '~widgets/MaskLoader';
import ListDateFormatter from './ListDateFormatter';

export default {
  props: ['marker'],
  data: () => ({
    list: [],
    loading: true,
    initialized: false,
    error: false,
    page: 1,
  }),
  components: {
    ListHeader,
    MessageColored,
    SplashLoader,
    MaskLoader,
    Pagination,
  },
  mounted() {
    this.load();
  },
  methods: {
    async load(top) {
      let params = null;
      this.loading = true;
      if (top) {
        this.$refs.pagination.top();
      }
      const { data } = await this.$api.res('message_markers', 'a4sex').load({
        marker: this.marker,
        page: this.page,
      });
      this.list = data;
      this.loading = false;
      this.initialized = true;
    },
    reset(marker) {
      this.page = 1;
      if (isFinite(marker)) {
        this.marker = marker;
      }
      this.load(true);
    },
    date(item, index) {
      let previous = null;
      if (index && index < this.list.length) {
        previous = this.list[index - 1].updated;
      }
      return ListDateFormatter.date(item.updated, previous);
    },
    next() {
      this.page += 1;
      this.load(true);
    },
    reload() {
      this.load(true);
    },
    abort() {
      this.loading = false;
    },
  },
}
</script>

<template>
  <div class="">
    <div class="reference">
      Пожалуйста, нажмите "Арбитраж", у того решения, которое по вашему мнению вынесено не верно.
      Можно добавить комментарий для арбитра, поясняющий причину.
      <a class="link_simple" href="http://docs.freedomsex.info/blog/#/Сообщество/Администрирование?id=Мониторинг-решений" target="_blank">Подробнее..</a>
    </div>

    <ListHeader :marker="marker"/>

    <div class="messages-colored" v-if="initialized">
      <div v-if="list.length">
        <MessageColored :resource="item" v-for="(item, index) in list" :key="item.id">
          <div class="date_sep" v-if="date(item, index)">
            <span>{{date(item, index)}}</span>
          </div>
        </MessageColored>
      </div>

      <div class="messages-colored__placeholder" v-if="!loading && !list.length">
        <h4>Конец списка</h4>
      </div>

      <div class="messages-colored__pages">
        <Pagination ref="pagination" @next="next" @reload="reload" @reset="reset" />
      </div>

      <MaskLoader @cancel="abort" v-if="loading"/>
    </div>
    <SplashLoader v-else/>
  </div>
</template>

<style lang="less">
.messages-colored {
  position: relative;
  min-height: 200px;

  &__item {
    margin-bottom: 15px;
    .material-icons {
      font-size: 14px;
      line-height: 1;
      vertical-align: top;
    }
  }

  a {
    display: inline-block;
  }
  &__pages {
    font-size: 16px;
    margin-top: @indent-lg;
  }
  &__placeholder {
    padding: @indent-lg 0 @indent-sm;
  }
  h4 {
    color: @gray-dark;
  }
}
</style>
