<script>
export default {
  props: ['full', 'time'],
  data: () => ({
    auth: false,
  }),
  mounted() {
    this.check();
  },
  methods: {
    check() {
      this.auth = this.$store.getters['token/auth']();
      if (!this.auth) {
        setTimeout(this.check, this.time || 1000);
      }
    }
  },
}
</script>

<template>
  <span>
    <template v-if="auth">
      <slot></slot>
    </template>
    <template v-else-if="full">
      •••
    </template>
  </span>

</template>

<style lang="less">

</style>
