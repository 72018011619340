<script>
import DefaultActivity from '@freedomsex/activity-component';
import AccountComponent from '@freedomsex/account-component';

export default {
  props: ['id', 'resource'],
  components: {
    DefaultActivity,
    AccountComponent,
  },
  data: () => ({
    human: null,
    error: false,
  }),
  mounted() {
    if (this.resource) {
      this.human = this.resource;
    } else {
      this.load();
    }
  },
  methods: {
    async load() {
      try {
        const { data } = await this.$api.res('users', 'search').get({ id: this.id });
        this.human = data;
      } catch (e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <DefaultActivity caption="Анкета" type="wrapped" @close="$emit('close')">
    <div style="max-width: 540px;" v-if="human">
      <AccountComponent :human="human" />
    </div>
    <div v-else-if="!error">
      Загружаю...
    </div>
    <div v-if="error">
      Нет данных
    </div>
  </DefaultActivity>
</template>

<style lang="less"></style>
