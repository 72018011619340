import Vue from 'vue';
import VueRouter from 'vue-router';
// import {store} from '~store';
Vue.use(VueRouter);

// // FIX Back go(-1)
// function backFix(to, from) {
//   if (from.path != '/') {
//     store.commit('navigate', from.path);
//   }
// }

// function chunkFailed(error) {
//   if (/loading chunk \d* failed./i.test(error.message)) {
//     global.App.failedChunk = true;
//   }
// }
// ---

const routes = [
  // {
  //   path: '/write/:humanId(\\d+)/(.*)?',
  //   name: 'quickWrite',
  //   props: true,
  // },
  // {
  //   path: '/initial/(.*)?',
  //   name: 'initial',
  //   component: () => import('~components/contacts/ContactInitial'),
  //   props: {
  //     reply: true,
  //   },
  // },
  {
    path: '/auth-sync',
    component: () => import('~modules/TokenAuth.vue'),
  },
  {
    path: '/user-anketa/:id',
    component: () => import('~activities/AnketaActivity.vue'),
    props: true,
  },
  {
    path: '/user-history/:id',
    component: () => import('~activities/UserHistory.vue'),
    props: true,
  },
  {
    path: '/user-profile/:id',
    component: () => import('~activities/user-profile/UserProfile.vue'),
    props: true,
  },
  {
    path: '/user-ban/:id',
    component: () => import('~activities/user-profile/BanUserActivity.vue'),
    props: true,
  },
  {
    path: '/admin-profile/:id',
    component: () => import('~activities/AdminProfile.vue'),
    props: true,
  },
  {
    path: '/message-history/:id',
    component: () => import('~activities/MessageHistory.vue'),
    props: true,
  },
  {
    path: '/admin-chat',
    component: () => import('~activities/AdminChat.vue'),
    props: true,
  },
  {
    path: '/admin-comments',
    component: () => import('~activities/CommentCollector.vue'),
    props: true,
  },
  {
    path: '/admin-watch',
    component: () => import('~activities/WatcherActivity.vue'),
    props: true,
  },
  {
    path: '/admin-bot',
    component: () => import('~activities/BotActivity.vue'),
    props: true,
  },
  {
    path: '/admin-search',
    component: () => import('~activities/SearchActivity.vue'),
    props: true,
  },
  // {
  //   path: '/feedback',
  //   component: () => import('~activities/notice/FeedbackActivity'),
  // },
];

const router = new VueRouter({
  // mode: 'history',
  routes,
});

// router.onError(chunkFailed);
// router.afterEach(backFix);

export { Vue, router };
