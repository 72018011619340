export default {
  tasks: {
    rank({ api, store, cache }) {
      const userId = store.state.token.uid;
      const key = `moderator-rank__${userId}`;
      return [
        cache.load(key, store.state.moderator.rank),
        api.res('moderators', 'a4sex').load({ userId }).then(({ data }) => {
          const { rank } = data[0];
          cache.save(key, rank);
          store.commit('moderator/rank', rank);
          return rank;
        }),
      ];
    },
  },
};
