<script>
export default {
  props: [],
  data: () => ({

  })
}
</script>

<template>
  <div class="splash-loader">
    Загружаю...
  </div>
</template>

<style lang="less">
.splash-loader {
  min-height: 80px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
</style>
