export default {
  tasks: {
    status({ api, cache }, id) {
      const key = `user-status__${id}`;
      return [
        cache.load(key, false),
        api.res('user_statuses', 'a4sex').get({ id }).then(({ data }) => {
          cache.save(key, data);
          return data;
        }),
      ];
    },

    lock({ api, cache }, id) {
      const key = `user-status__${id}`;
      const state = cache.load(key, { status: 0 });
      state.status = 4;
      cache.save(key, state);
      api.res('userinfo/lock', 'raw').post({ id }).then(() => {
        // return data;
      });
    },
    boost({ api }, id) {
      api.res('userinfo/boost', 'raw').post({ id }).then(() => {
        // return data;
      });
    },
    unlock({ api, cache }, id) {
      const key = `user-status__${id}`;
      const state = cache.load(key, { status: 0 });
      state.status = 0;
      cache.save(key, state);
      api.res('userinfo/unlock', 'raw').post({ id }).then(() => {
        // return data;
      });
    },

    locks({ api, cache }, userId) {
      const key = `user-locks__${userId}`;
      return [
        cache.load(key, []),
        api.res('user_claims', 'a4sex').load({ userId }).then(({ data }) => {
          cache.save(key, data);
          return data;
        }),
      ];
    },
    messageStats({ api, cache }, userId) {
      const key = `user-message-stats__${userId}`;
      return [
        cache.load(key, false),
        api.res('message_stats', 'a4sex').load({ userId }).then(({ data }) => {
          const item = data[0] || null;
          cache.save(key, item);
          return item;
        }),
      ];
    },
  },
};
