import Worker from '@freedomsex/service-worker';
import auth from './modules/auth';
import search from './modules/search';
import profile from './modules/profile';
import notice from './modules/notice';
import user from './modules/user';
import moderator from './modules/moderator';

const worker = new Worker({
  tasks: {
    //
  },
  modules: {
    auth,
    search,
    profile,
    notice,
    user,
    moderator,
  },
});

export default worker;
