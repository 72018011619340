<script>
export default {
  props: ['section'],
  data: () => ({
    selected: null,
    sections: [
      {title: 'Интерфейс модератора', section: 'moderator'},
      {title: 'Администрирование сообщества', section: 'admin'},
      {title: 'Мониторинг системы', section: 'developer'},
    ],
    routes: {
      index: {
        title: 'Система безопасности сайта',
        sections: [
          // {section: 'index', title: 'Система безопасности'},
          // {section: 'moderator', title: 'Интерфейс модератора'},
        ],
        categories: [
          // {title: 'Вход', link: '/index/login/', color: 'bg_dblu'},
          // {title: 'Справка', link: '/index/help/', color: 'bg_dgrn'},
          // {title: 'Документация', link: 'http://docs.freedomsex.info/', color: 'bg_dblu', blank: true},
          // {title: 'Форум', link: 'https://freedomsex.info/', color: 'bg_dblu', blank: true},
        ],
      },
      admin: {
        title: 'Система безопасности сайта, всё под контролем профессионалов',
        sections: [
          {section: 'admin', title: 'Администрирование сообщества'},
          {section: 'moderator', title: 'Интерфейс модератора'},
        ],
        categories: [
          {link: '/admin/closed/', title: 'Решения', color: 'bg_dblu'},
          {link: '/userinfo/users/', title: 'Пользователи', color: 'bg_dblu'},
          {link: '/userinfo/abuse/', title: 'Замечания', color: 'bg_dblu'},
          {link: '/admin/help/', title: 'Ответить', color: 'bg_dgrn'},
          {link: '/history/actban/', title: 'Блокировка', color: 'bg_dblu'},
          // {link: '/admin/bannam/', title: 'Имена', color: '', },
          // {link: '/admin/cities/', title: 'Города', color: ''},
          // {link: '/argue/list/', title: '*', color: ''},
        ],
      },
      moderator: {
        title: 'Интерфейс сотрудников системы безопасности, безопасность под контролем',
        sections: [
          {title: 'Интерфейс модератора', section: 'moderator'},
          {title: 'Администрирование сообщества', section: 'admin'},
          {title: 'Мониторинг системы', section: 'developer'},
        ],
        categories: [
          {title: 'Новые', link: '/security/action/', color: 'bg_dblu'},
          {title: 'Решение', link: '/security/approve/', color: 'bg_dblu'},
          {title: 'Арбитраж', link: '/security/arbitr/', color: 'bg_dblu'},
          {title: 'Вердикт', link: '/security/verdict/', color: 'bg_dblu'},
          {title: 'Помощь', link: '/security/help/', color: 'bg_dgrn'},
          {title: 'Ключи', link: '/security/names/', color: 'bg_dblu'},
          {title: 'Достижения', link: '/security/progress/', color: 'bg_dblu'},
        ],
      },
      developer: {
        title: 'Разработка системы. Обсуждение, идеи, реализация нового функционала',
        sections: [
          {title: 'Разработчики системы', section: 'developer' },
          {title: 'Администрирование сообщества', section: 'admin'},
        ],
        categories: [
          {title: 'Голосование', link: '/develop/voting/', color: 'bg_dblu'},
          // {title: 'Поиск', link: '/develop/search/', color: 'bg_dblu'},
          {title: 'Модераторы', link: '/argue/admins/', color: 'bg_dblu'},
          {title: 'Отзывы', link: '/develop/reviews/', color: 'bg_dgrn'},
          {title: 'Обсуждение', link: '/develop/queue/', color: 'bg_dblu'},
          {title: 'История', link: '/history/votes/', color: 'bg_dblu'},
          // {title: '*', link: '/argue/list/', color: ''},
        ],
      },

      // developer: {
      //   sections: [
      //     {title: '', link: '', blank: true},
      //   ],
      //   categories: [
      //     {title: '', link: '', color: 'bg_dblu'},
      //   ],
      //   context: {title: '', link: ''},
      // },

    },

  }),
  mounted() {
    document.title = this.routes[this.active].title;
  },
  computed: {
    active() {
      return this.selected || this.section;
    },
    categories() {
      return this.routes[this.active].categories;
    },
  },
  methods: {
//
  },
}
</script>

<template>
  <div class="main-menu">
    <div class="main-menu__router" v-if="sections.length">
      <div class="router-links">
        <span class="router-links__item" v-for="item in sections" @click="selected = item.section">
          {{item.title}}
        </span>
      </div>
    </div>

    <div class="main-menu__section">
      <a class="btn_menu" v-for="item in categories"
       :key="item.link" :href="item.link"
       :class="item.color">{{item.title}}</a>
    </div>
  </div>
</template>

<style lang="less">
.main-menu {

  &__router {
    .sliding();
    color: @gray-dark;
    font-size: 14px;
    position: relative;
    margin: 5px 5px;
    @media @desktop {
      margin: 5px 0px;
    }
  }


  .router-links {
    .colon-link-menu();
  }

  &__section {
    .sliding();
    padding: 10px 0;
    border-top: 1px solid @smoke;

    .btn_menu {
      border-radius: 0px;
      color: @white;
      display: inline-block;
      font-size: 15px;
      margin-bottom: 3px;
      margin-right: 5px;
      padding: 10px 10px;
      text-align: center;
      text-decoration: none;
      vertical-align: top;
      &:hover {
        text-decoration: none;
        color: @white;
        opacity: 0.8;
      }
    }

    .btn_git-hub {
      padding: 0px; margin: 0 5px;
      background: url('/static/img/icon/github.png') no-repeat;
      width: 100px;
      height: 37px;
    }
  }

}

</style>
