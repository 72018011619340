<script>
export default {
  props: ['uid'],
  components: {
    ListLoader: () => import('~widgets/element/ListLoader.vue'),
  },
  data: () => ({
    list: [],
    loading: true,
  }),
  mounted() {
    this.load();
  },
  computed: {
    //
  },

  methods: {
    async load() {
      const [data, promice] = this.$service.run('notice/feedback', this.uid);
      this.list = data;
      this.list = await promice;
      this.loading = false;
    },
  }
}
</script>

<template>
  <div class="user-feedback">
    <p>
      <h3>Ответы пользователя</h3>

      <div class="feedback-item" v-for="(item) in list" v-if="list" :key="item.id">
        <div class="feedback-item__summary">
          Ответ получен {{$moment(item.added).fromNow()}}
        </div>
        <div class="feedback-item__body">
          {{item.message}}
        </div>
      </div>
      <ListLoader :busy="loading" :empty="!list.length" :error="0"/>
    </p>
  </div>
</template>

<style lang="less">
.feedback-item {
  border: 1px solid #EEE;
  margin-bottom: 10px;

  &__summary {
    font-size: 12px;
    padding: 2px 10px;
    color: #666;
    border: 1px solid #EEE;
    border-width: 0 1px 1px 0;
    background: #f7f7f7;
  }

  &__body {
    padding: 10px 10px;
  }
}
</style>
