<script>
import UserHistory from '~activities/UserHistory.vue';
import ProfileOldWidget from './widgets/profile-old-widget.vue';
import ProfileLastWidget from './widgets/profile-last-widget.vue';
import UserAnketa from '~widgets/UserAnketa.vue';

export default {
  props: ['user', 'time', 'last'],
  data: () => ({
    dialogs: {
      userDataHistory: false,
    },
  }),
  components: {
    UserAnketa,
    ProfileOldWidget,
    ProfileLastWidget,
    UserHistory,
  },
};
</script>

<template>
  <div class="">
    <div class="profile-info__main">
      <profile-last-widget :last="last" :sex="user.sex"></profile-last-widget>
      <profile-old-widget :time="time"></profile-old-widget>
    </div>

    <UserAnketa :id="user.id"/>

    <div class="user-info">
      <span class="user-info__wrapper">
        <span class="user-info__name">{{user.name}}</span>
        <span class="user-info__age">{{user.age}}</span>
        {{user.city}}
      </span>
      <button class="btn btn-default btn-xs" @click="dialogs.userDataHistory = true">
        <span aria-hidden="true" class="glyphicon glyphicon-time"></span>
        История
      </button>
      <span class="profile-info__age"></span>
      <UserHistory :id="user.id" v-if="dialogs.userDataHistory"
       @close="dialogs.userDataHistory = false"/>
    </div>
  </div>
</template>

<style lang="less">
.profile-info__main {
  vertical-align: middle;
  position: relative;
  margin-bottom: @indent-md;
}

.user-info {
  margin-bottom: @indent-sm;
  font-size: @font-lg;
  &__wrapper {
    margin-right: @indent-xs;
  }
  &__name {
    font-size: @font-xl;
  }
  &__age {
    color: @orange-dark;
  }

}
</style>
