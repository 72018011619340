import moment from 'moment';

moment.locale('ru');

export default {
  currDate(date) {
    return moment(date).date();
  },
  prevDate(date) {
    return date ? moment(date).date() : null;
  },
  formatted(date) {
    let result = moment(date).format('D MMM');
    if (this.currDate(date) === moment().date()) {
      result = 'Сегодня';
    }
    if (this.currDate(date) === moment().subtract(1, 'day').date()) {
      result = 'Вчера';
    }
    return result;
  },
  date(curr, prev) {
    if (curr && this.prevDate(prev) != this.currDate(curr)) {
      return this.formatted(curr);
    }
    return null;
  },
};
