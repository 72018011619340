export default {
  tasks: {
    send({ api, store }, { userId, textId }) {
      return api.res('notifications', 'notice').post({
        userId, textId, sender: store.state.token.uid,
      });
    },
    variants({ api, cache }) {
      const key = 'notice-variants__list';
      return [
        cache.load(key, []),
        api.res('notice_texts', 'notice').load({ pagination: false }).then(({ data }) => {
          cache.save(key, data);
          return data;
        }),
      ];
    },
    sended({ api, cache }, userId) {
      const key = `notice-sended__list--${userId}`;
      return [
        cache.load(key, []),
        api.res('notifications', 'notice').load({ userId }).then(({ data }) => {
          cache.save(key, data);
          return data;
        }),
      ];
    },
    feedback({ api, cache }, userId) {
      const key = `notice-replies__list--${userId}`;
      return [
        cache.load(key, []),
        api.res('feedback', 'notice').load({ userId }).then(({ data }) => {
          cache.save(key, data);
          return data;
        }),
      ];
    },
  },
};
