<script>
export default {
  props: ['marker'],
  data: () => ({
    texts: {
      '1': {
        text: 'Необоснованные и отклоненные жалобы. Обычные сообщения.',
        link: 'Быстрый-старт?id=Регламент-сотрудника',
      },
      '2': {
        text: 'Предложение вирта или знакомства с целью вирта.',
        link: 'Быстрый-старт?id=Секс-и-виртуальный-секс',
      },
      '4': {
        text: 'Сообщения оскорбительного или противоправного содержания.',
        link: 'Быстрый-старт?id=Оскорбления',
      },
      '8': {
        text: 'Мошенники, предложения оплаты, индивидуалки, спам ссылки.',
        link: 'Быстрый-старт?id=Строго-запрещено',
      },
      '0': {
        text: 'Пропущенные системой жалобы с подозрением на технический флуд.',
        link: 'Администрирование?id=Бездна-решений',
      },
    },
  }),
  computed: {
    style() {
      return {
        'bg_gra br_gra': this.marker == '0',
        'bg_grn br_grn': this.marker == '1',
        'bg_mgn br_mgn': this.marker == '2',
        'bg_blu br_blu': this.marker == '3',
        'bg_yel br_yel': this.marker == '4',
        'bg_red br_red': this.marker == '8',
      };
    }
  },
}
</script>

<template>
  <div class="messages-list-header line_stnd brdlft" :class="style">
    {{texts[marker].text}}
    <a class="link_simple"
     :href="'http://docs.freedomsex.info/blog/#/Сообщество/'+texts[marker].link" target="_blank">
      Справка.
    </a>
  </div>
</template>

<style lang="less">
.messages-list-header {
  color: #333 !important;
  margin-bottom: 15px !important;
  font-size: 14px !important;
}
</style>
