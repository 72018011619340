import Api from 'axios-rest-api';

let config = {
  NET_DELAY: 0,
  API_AUTH: 'http://localhost:8001',
  API_DIALOG: 'http://localhost:8000',
};
if (process.env.NODE_ENV === 'production') {
  config = {
    NET_DELAY: 0,
    API_AUTH: 'https://auth.api.dc2.a4sex.net',
    API_DIALOG: 'https://dialog.api.dc2.a4sex.net',
  };
}

const resourses = [];

resourses.default = {
  delay: config.NET_DELAY,
  host: config.API_DIALOG,
  prefix: 'api',
  // version: 'v1',
};

resourses.raw = {
  prefix: '',
  version: '',
  host: '',
};

resourses.auth = {
  prefix: 'api',
  version: 'v1',
  host: config.API_AUTH,
};

resourses.a4sex = {
  prefix: 'api',
  version: '',
};

resourses.a4sexRaw = {
  prefix: '',
  version: '',
};

resourses.notice = {
  prefix: 'api',
  host: config.API_DIALOG,
};

resourses.search = {
  prefix: 'api',
  host: config.API_DIALOG,
  version: '',
};

const api = new Api(resourses);
export default api;
