<script>
import _ from 'underscore';
import MarkerTextColored from '@freedomsex/marker-text-colored';

export default {
  props: ['uid', 'texts'],
  components: {
    MarkerTextColored,
  },
  data: () => ({
    list: [],
    loading: true,
  }),
  mounted() {
    this.load();
  },
  computed: {
    messages() {
      let texts = {};
      _.each(this.texts, (item) => {
        texts[item.textId] = item;
      });
      return texts;
    },
  },

  methods: {

    async load() {
      const [data, promice] = this.$service.run('notice/sended', this.uid);
      this.list = data;
      this.list = await promice;
      this.loading = false;
    },
    t(item) {
      return this.messages[item.textId];
    },
  }
}
</script>

<template>
  <div class="user-notices">
    <p>
      <h3>Отправленные ранее</h3>

      <div v-for="(item) in list" v-if="list" :key="item.id">
        <MarkerTextColored :type="t(item).type">
          <template slot="summary">
            Отправил
            <span class="link_simple gray" @click="$router.push('/admin-profile/'+item.sender)">
              @{{item.sender}} -
            </span>
            {{$moment(item.added).fromNow(true)}} -
            Статус:
            <span class="text-info" v-if="!item.readed">Доставлено</span>
            <span class="text-success" v-else-if="!item.deleted" >Прочитано</span>
            <span class="text-danger" v-else>Удалено</span>
          </template>

          {{t(item).title}}
        </MarkerTextColored>
      </div>

      <h4 v-if="loading">
        Загружаю...
      </h4>
      <h4 v-else-if="!list.length">
        Уведомлений нет
      </h4>

    </p>
  </div>
</template>

<style lang="less">
.user-notices {
  h4 {
    color: @gray-dark;
  }
}
</style>
