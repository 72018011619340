import Vue from 'vue';
import Vuex from 'vuex';

import token from './modules/token';
import settings from './modules/settings';
import moderator from './modules/moderator';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authorized: false,
  },
  mutations: {
    authorize(state, value) {
      state.authorized = (value === true);
    },
  },
  actions: {
    //
  },
  modules: {
    token,
    settings,
    moderator,
  },
});
