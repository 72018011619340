<script>
import _ from 'underscore';

import DefaultActivity from '@freedomsex/activity-component';
import UserNotices from '~widgets/notifications/UserNotices.vue';
import UserFeedback from '~widgets/notifications/UserFeedback';


export default {
  props: ['uid'],
  data: () => ({
    list: [],
    selected: 0,
    success: false,
    process: false,
    loading: true,
  }),
  mounted() {
    this.load();
  },
  components: {
    DefaultActivity,
    UserFeedback,
    UserNotices,
  },
  methods: {
    async load() {
      const [data, promice] = this.$service.run('notice/variants');
      this.onLoad(data);
      this.onLoad(await promice);
      this.loading = false;
    },
    onLoad(data) {
      this.list = data;
      this.selected = this.lessList[0].textId;
    },
    async send() {
      this.process = true;
      const { data } = await this.$service.run('notice/send', {
        userId: parseInt(this.uid, 10),
        textId: this.selected,
      });
      this.onSend();
    },
    onSend() {
      this.process = false;
      this.success = true;
      this.$refs.sended.load();
      setTimeout(() => this.success = false, 3000);
    },
    // date(value) {
    //   return this.$moment(value).fromNow();
    // },
  },
  computed: {

    lessList() {
      return _.where(this.list, {less: true});
    },

    text() {
      if (this.list.length && this.selected) {
        let item = _.where(this.list, {textId: this.selected, locale: 'RU'})[0];
        return item.text;
      }
      return '';
    }
  },
};
</script>

<template>
  <DefaultActivity caption="Уведомления нарушителя" type="wrapped" @close="$emit('close')">

    <div class="reference">
      Отправьте уведомление нарушителю, если необходимо. Уведомления стандартные и можно отправить только в том виде, в котором есть. Без редактирования.
      <a href="" target="_blank" class="link_simple">Подробнее..</a>
    </div>

    <div class="special-form">
      <div class="" style="max-width: 500px;">
        <div class="form-group">
          <select class="form-control" v-model="selected">
            <option v-for="(item, index) in lessList" :key="item.id"
             :value="item.textId">{{item.title}}</option>
          </select>
        </div>

        <div class="form-group">
          <textarea class="form-control" style="display: block; width: 100%; min-height: 100px;" readonly>{{text}}</textarea>
        </div>

        <div class="form-group">
          <button class="btn btn-primary" @click="send()" :disabled="process">
            Отправить уведомление
          </button>
          <button class="btn btn-success btn-link" v-if="success" @click="success = false">
            Отправлено
          </button>
        </div>
      </div>

    </div>

    <UserNotices ref="sended" :uid="uid" :texts="list" v-if="list.length" />
    <UserFeedback ref="feedback" :uid="uid"/>


  </DefaultActivity>
</template>

<style lang="less">
.special-form {
  background: @alert-sand;
  padding: @indent-md @indent-md;
  border: 1px solid #f6e4a2;
  border-width: 1px 0 1px;
  border-radius: 4px;
}
</style>
