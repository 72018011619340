import VueI18n from 'vue-i18n';
import Vue from 'vue';
import VueMeta from 'vue-meta';

import moment from 'moment';
// import 'moment/locale/ru';
import store from './store';

import { router } from '~config/router';

// import 'material-design-icons/styles.css'; // CSS

import '~legacy/comm-list';
import '~legacy/idea-list';
import '~legacy/revs-list';
import '~legacy/help-list';
import '~legacy/quick-word';
import '~legacy/extra';

import TimeStatus from '~components/profile-user/time-status';
import SendNotice from '~activities/SendNotice';
import MessageList from '~widgets/messages/MessageList';
import VotingList from '~widgets/messages/VotingList';
import Authenticator from '~modules/Authenticator';
import AuthRequired from '~modules/AuthRequired';

// import TokenAuth from '~modules/TokenAuth';

import AdminMenu from '~widgets/AdminMenu';
import InfoContext from '~components/InfoContext';
import DefaultPage from '~components/pages/DefaultPage';
import NavBar from '~widgets/NavBar';

import ApiPlugin from '~plugins/RestAPI';
import ServiceWorker from '~plugins/Service';
import App from './App';

moment.locale('ru');
Vue.prototype.$moment = moment; // For main.less only

Vue.use(VueMeta);
Vue.use(ApiPlugin);
Vue.use(ServiceWorker);
Vue.use(VueI18n);
//
// const router = new VueRouter({
// });

const i18n = new VueI18n({
  locale: 'ru', // set locale
  fallbackLocale: 'ru',
  // silentTranslationWarn: true,
  messages: {},
});

new Vue({ // eslint-disable-line no-new
  components: {
    App,
    AdminMenu,
    TimeStatus,
    SendNotice,
    MessageList,
    Authenticator,
    AuthRequired,
    InfoContext,
    DefaultPage,
    VotingList,
    NavBar,
  },
  data() {
    return {
      dialog: {
        sendNotice: false,
      },
    };
  },
  created() {
    this.$service.run('auth/restore');
  },
  mounted() {
    //
  },
  el: '#app',
  store,
  router,
  i18n,
  // render: h => h(App),
});
// console.log(A);
