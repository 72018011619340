<script>
export default {
  props: ['full'],
  data: () => ({
    timeout: false,
    verbose: false,
    timers: {
      verbose: null,
      timeout: null,
    },
  }),
  mounted() {
    this.timers.verbose = setTimeout(() => { this.verbose = true; }, 1500);
    this.timers.timeout = setTimeout(() => { this.timeout = true; }, 5000);
  },
  destroyed() {
    clearTimeout(this.timers.verbose);
    clearTimeout(this.timers.timeout);
  },
}
</script>

<template>
  <div class="update-mask" :class="full ? 'full' : 'partial'">
    <div class="loader-dock">
      <i class="material-icons" v-if="verbose && !timeout">more_horiz</i>
      <button class="btn btn-default" v-if="timeout" @click="$emit('cancel')">
        <span class="glyphicon glyphicon-repeat" aria-hidden="true"></span>
        Остановить
      </button>
    </div>
  </div>
</template>

<style lang="less">
.update-mask {
  .material-icons {
    font-size: 48px;
  }

  .loader-dock {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #999;
  background: rgba(#fff, 0.8);

  &.full {
    position: fixed;
  }
  &.partial {
    position: absolute;
  }
}
</style>
