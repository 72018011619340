<script>
import BottomBar from '@freedomsex/bottom-bar';

export default {
  props: [],
  data: () => ({

  }),
  computed: {
    //
  },
  methods: {
    search() {
      //
    },
  },
  components: {
    BottomBar,
  },
};
</script>

<template>
  <BottomBar>
    <div class="nav-button" @click="$router.push('/admin-chat')">
      <div class="nav-button__icon">
        <i class="material-icons">&#xE0BF;</i>
      </div>
      <div class="nav-button__caption">
        Чат
      </div>
    </div>
    <div class="nav-button" @click="$router.push('/admin-comments')">
      <div class="nav-button__icon">
        <i class="material-icons">error_outline</i>
      </div>
      <div class="nav-button__caption">
        Алерт
      </div>
    </div>
    <div class="nav-button" @click="$router.push('/admin-watch')">
      <div class="nav-button__icon">
        <i class="material-icons">visibility</i>
      </div>
      <div class="nav-button__caption">
        Важно
      </div>
    </div>
    <!-- <div class="nav-button" @click="$router.push('/admin-bot')">
      <div class="nav-button__icon">
        <i class="material-icons">adb</i>
      </div>
      <div class="nav-button__caption">
        Бот
      </div>
    </div> -->
  </BottomBar>
</template>

<style lang="less">
.bottom-bar__wrapper {
  justify-content: space-around !important;
}

@media (prefers-color-scheme: dark) {
  .bottom-bar {
    .nav-button {
      color: @smoke;
    }
    background-color: @black;
    box-shadow: 0 -2px 3px 0 rgba(255, 255, 255, 0.08);
  }

}
</style>
