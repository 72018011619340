import service from '../service/worker';
import api from '../config/api';
import store from '../store';
import cache from '../config/cache';

export default {
  install(Vue) {
    service.api(api);
    service.store(store);
    service.cache(cache);
    Vue.prototype.$service = service;
  },
};
