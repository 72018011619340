<script>
export default {
  data() {
    return {
      dialog: {
        sendNotice: false,
      },
    };
  },
  mounted() {
    // this.$service.run('theme');
  },
};
</script>

<style lang="less">
@import '~styles/main.less';
</style>
